<template>
    <div id="videoBuy">
      <div class="container">
        <!-- 视频内容开始 -->
        <div class="box box-1">
          <div class="thumb icon">
            <van-image
              width="100%"
              height="100%"
              fit="cover"
              :src="detail.coverAcrossTemp"
            />
          </div>
          <div class="right">
            <div class="top">
              <div class="title text-ellipsis">{{ detail.name }}</div>
              <div class="desrc">動画タイプ：{{ detail.typeName }}</div>
              <div class="desrc" v-if="getItem.length!==0">動画の画質：{{ getItem[0].name }}</div>
              <div class="desrc">カテゴリー：{{ detail.categoryName }}</div>
              <div class="desrc" v-if="getItem.length">お値段：{{ detail[getItem[0].typePrice] }}円</div>
            </div>
          </div>
        </div>
        <!-- 视频内容结束 -->

        <!-- 视频清晰度选择开始 -->
        <div class="box box-4">
          <div class="title">
            <div class="start">動画の画質</div>
            <div class="end"></div>
          </div>
          <ul class="list" v-if="definitionCheck">
            <li class="list_item"
              @click="changeDefinition(item)"
              :class="{active:item.type === resultType}"
              v-for="item in definitionCheck"
              :key="item.type"
              v-show="!detail[item.isBuy]"
            >
              <div class="top">{{ item.name }}</div>
              <div class="price">{{detail[item.typePrice]}}円</div>
            </li>
          </ul>
        </div>
        <!-- 视频清晰度选择结束 -->

        <!-- 优惠券开始 -->
        <div class="box box-2">
          <div class="title">
            <div class="start">クーポン</div>
            <div class="end" @click="currentIndex=-1;id=''">クーポンを使いません</div>
          </div>
          <div class="coupon">
            <ul class="couponUl" v-if="couponList.length">
              <li v-for="(item,index) in couponList" :key="item.id" :class="{'active':index===currentIndex}" @click="countClick(index,item.id)">
                <div class="left">
                  <span>{{ item.discountName }}</span>
                </div>
                <div class="right">
                  <span>{{ item.price }}円</span>
                  <i class="icon icon-check"></i>
                </div>
              </li>
            </ul>
            <div v-else class="noData">
              クーポンはありません
            </div>
          </div>
        </div>
        <!-- 优惠券结束 -->

        <!-- 总计算价格开始 -->
        <div class="box box-3">
          <div class="left">合計金額</div>
          <div class="right">
            <span class="price">
              <span class="big">{{ getAllPrice }}</span>円
            </span>
          </div>
        </div>
        <!-- 总计算价格结束 -->

      </div>
      <div class="footer">
        <div class="submit-btn" @click="onSubmit">支払</div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VideoBuy',
  components: {},
  data () {
    return {
      select: {},
      // vipList: [{
      //   title: '水曜日が消えた VRシーン体験 HD',
      //   desrc: '４K、HDを含むすべての動画を見放題！',
      //   icon: 'thumb-1',
      //   price: 3450,
      //   marketPrice: 4450
      // }, {
      //   title: 'スタンダード会員',
      //   desrc: 'HDとSD動画を見放題！',
      //   icon: 'thumb-2',
      //   price: 1980,
      //   marketPrice: 2980
      // }, {
      //   title: 'ベーシク会員',
      //   icon: 'thumb-3',
      //   desrc: 'SD動画を見放題！',
      //   price: 1490,
      //   marketPrice: 2490
      // }],
      checked: true,
      activeIcon: require('@/assets/images/icon_checkbox_01.png'),
      inactiveIcon: '',
      count: 2,
      currentIndex: -1,
      detail: {},
      couponArr: [],

      // 清晰度选择
      definitionCheck: [
        { type: 1, name: 'SD', typePrice: 'standardPrice', isBuy: 'standardIsBuy' },
        { type: 2, name: 'HD', typePrice: 'highPrice', isBuy: 'highIsBuy' },
        { type: 3, name: '4K', typePrice: 'exceedPrice', isBuy: 'exceedIsBuy' }
      ],

      standardIsBuy: false, // 720
      highIsBuy: false, // 1080
      exceedIsBuy: false, // 4k

      resultType: 1, // 最终购买的视频清晰度
      videoId: 0,

      couponList: [],

      id: '',

      isSubmit: true
    };
  },
  async mounted () {
    const { $route: { query: { type, videoId } } } = this;

    /* 计算清晰度框的选择 */
    const { personInfo, definitionCheck } = this;

    /* 如果没权限且没买 */
    if (personInfo.jurisdiction.length !== 0) {
      // definitionCheck.shift();
      if (personInfo.jurisdiction.includes(1)) {
        // definitionCheck.shift();
      }
    }

    this.videoId = videoId * 1;
    this.resultType = type * 1 || definitionCheck[0].type;
    this.definitionCheck = definitionCheck;

    await this.getDetail();
    this.getList();
  },
  computed: {
    ...mapGetters(['personInfo']),

    getItem () {
      const { definitionCheck, resultType } = this;
      return definitionCheck.filter((item) => item.type === resultType);
    },

    /* 计算清晰度框的选择 */
    getDefinition () {
      // console.log(this.personInfo);
      const { personInfo } = this;
      let newList;
      if (personInfo.jurisdiction.length !== 0) {
        newList = this.definitionCheck;
      } else {
        newList = this.definitionCheck;
      }
      return newList;
    },

    /* 计算总价格 */
    getAllPrice () {
      let { detail, getItem } = this;
      let total;
      if (this.currentIndex !== -1) {
        total = getItem ? detail[getItem[0].typePrice] - this.couponList[this.currentIndex].price : [];
      } else {
        total = getItem ? detail[getItem[0].typePrice] : [];
      }
      return total;
    }
  },
  methods: {

    /* 获取订单信息 */
    async getDetail () {
      const { resultType: type, videoId } = this;

      this.$http.post('video/affirmOrder', {
        type,
        videoId
      }).then(async ({ data }) => {
        this.$set(data, 'resultPirce', 0);

        /* 前端进行判断优惠券的显隐状态 */
        // this.couponList = data.list;
        // data.list = data.list.filter((item) => item.price < data.realCost);
        this.standardIsBuy = data.standardIsBuy;
        this.highIsBuy = data.highIsBuy;
        this.exceedIsBuy = data.exceedIsBuy;

        this.detail = data;
        if (this.detail.type !== 3) {
          this.definitionCheck.pop();
        }
        console.log(this.definitionCheck);
      });

    },

    /* 获取优惠券列表 */
    getList () {
      const { resultType: type, videoId } = this;
      this.$http.post('video/availableCoupons', {
        type,
        videoId
      }).then((res) => {
        this.couponList = res.data;
      });
    },

    /* 切换优惠券选项 */
    countClick (index, id) {
      console.log(index, this.currentIndex);
      this.currentIndex = index;
      this.id = id;
    },

    /* 购买视频 */
    onSubmit () {
      const { $router, $route: { query: { videoId } } } = this;
      let { getAllPrice, id } = this;
      if (this.isSubmit) {
        this.$http.post('video/payOrder', {
          discountRelationId: parseInt(id) || '',
          payment: getAllPrice,
          type: this.resultType,
          videoId: parseInt(videoId)
        }, {
          showLoading: true
        }).then(async (res) => {
          this.isSubmit = false;
          // $router.push({
          //   name: 'VideoBuyResult',
          //   query: {
          //     state: res.data.state
          //   }
          // });
          const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
          elepay.handleCharge(res.data)
            .then((result) => {
              if (result.type === 'cancel') {
                console.log('取消支付');
              } else if (result.type === 'success') {
                $router.push({
                  name: 'VideoBuyResult',
                  params: {
                    type: 'success'
                  }
                });
              }
            })
            .catch((err) => {
              console.log('支付异常:', err);
              $router.push({
                name: 'VideoBuyResult',
                params: {
                  type: 'faild'
                }
              });
            });
        }).catch((e) => {
        });
      } else {
        return false;
      }
    },

    /* 切换清晰度 */
    changeDefinition (item) {
      this.resultType = item.type;
      // let itemPrice = this.detail[item.typePrice];
      // this.detail.list = this.couponList;
      // this.detail.list = this.detail.list.filter((item) => item.price < itemPrice);
      this.currentIndex = -1;
      this.getList();
      this.id = '';
    }
  }
};
</script>

<style scoped lang="less">
#videoBuy {
  .container {
    .box {
      display: flex;
      background-color: #232324;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .price {
        font-size: 24px;
        font-weight: bold;
        color: #ed544f;
        // margin-right: 24px;
        .big {
          font-size: 36px;
        }
      }

      &.box-1 {
        padding: 28px 22px;

        .thumb {
          flex-shrink: 0;
          width: 240px;
          height: 168px;
          margin-right: 32px;
          background-color: #2d2d2f;
          border-radius: 16px;

          &.thumb-1 {
            background-image: url("~@/assets/images/bg_podium_01.png");
          }

          &.thumb-2 {
            background-image: url("~@/assets/images/bg_podium_02.png");
          }

          &.thumb-3 {
            background-image: url("~@/assets/images/bg_podium_03.png");
          }
        }

        .right {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: space-between;

          .top {
            &:nth-child(n) {
              margin-bottom: 6px;
            }

            .title {
              font-size: 26px;
              line-height: 34px;
            }

            .desrc {
              font-size: 20px;
              line-height: 28px;
              opacity: 0.8;
            }
          }
        }
      }

      &.box-2 {
        display: block;
        padding: 20px 28px;

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 30px;

          .start {
            flex: 1;
            font-size: 30px;
            line-height: 40px;
          }

          .end {
            flex-shrink: 0;
            font-size: 20px;
            line-height: 28px;
            opacity: 0.7;
          }
        }

        .coupon {
          .couponUl {
            height: auto;

            li {
              display: flex;
              justify-content: space-between;
              height: 80px;
              margin-bottom: 20px;
              line-height: 80px;
              border-radius: 16px;
              opacity: 1;
              // background:linear-gradient(107deg, #FF814A 0%, #FF605A 100%);
              &.active {
                background: linear-gradient(107deg, #ff814a 0%, #ff605a 100%);

                .right {
                  .icon-check {
                    background-image: url("~@/assets/images/icon_checkbox_01.png");
                  }
                }
              }

              .left {
                width: 75%;
                height: 100%;
                padding-left: 86px;
                font-size: 26px;
                background-image: url("~@/assets/images/icon_coupon_01.png");
                background-repeat: no-repeat;
                background-position: 12px 20px;
              }

              .right {
                position: relative;
                width: 25%;
                font-size: 24px;
                background-repeat: no-repeat;
                background-position: right bottom;
                background-size: contain;
                opacity: 0.6;
                display: flex;
                align-items: center;
                .icon-check {
                  position: absolute;
                  right: 16px;
                  width: 42px;
                  height: 42px;
                  margin-left: 30px;
                  background-color: #fff;
                  border-radius: 50%;
                }
              }
            }
          }

          .noData {
            font-size: 20px;
            line-height: 28px;
            color: #fff;
            opacity: 0.7;
          }
        }
      }

      &.box-3 {
        padding: 20px 28px;

        .left {
          flex: 1;
          font-size: 30px;
          line-height: 40px;
        }

        .right {
          flex-shrink: 0;
        }
      }
      &.box-4 {
        flex-direction: column;
        padding: 20px 28px;
        .title{
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 15px;
          color: #FFFFFF;
          .end{
            font-size: 10px;
            color: #FFFFFF;
            opacity: 0.7;
          }
        }
        .list{
          display: flex;
          margin-top: 42px;
          .list_item{
            width: 33%;;
            padding: 14px 18px;
            border-radius: 8px;
            border: 2px solid #707070;
            &.active{
              border: none;
              background: linear-gradient(107deg, #FF814A 0%, #FF605A 100%);
            }
            .top{
              font-size: 40px;
              color: #FFFFFF;
            }
            .price{
              color: #FFFFFF;
              font-weight: 400;
              font-size: 32px;
            }
            &:not(:last-child){
              margin-right: 8px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 28px;
  }

  .popup-bindPay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 672px;
    height: 83%;
    max-height: 1118px;

    .content {
      width: 360px;
      font-size: 30px;
      font-weight: bold;
      line-height: 86px;
      text-align: center;
    }
  }
}
</style>